<template>
  <div class="app flex-row align-items-center">
    <vue-progress-bar></vue-progress-bar>
    <div class="container" @keydown.enter="login">
      <b-row class="justify-content-center">
        <b-col md="8">
           <b-card no-body class="p-4">
             <b-card-body>
               <h1><div class="brand"></div> N Zone Sports - Login</h1>
               <p class="text-muted">Sign In to your account</p>
               <b-input-group class="mb-3">
                 <b-input-group-prepend><b-input-group-text><i class="icon-user"></i></b-input-group-text></b-input-group-prepend>
                 <input type="text" v-model="username" class="form-control" placeholder="Username">
               </b-input-group>
               <b-input-group class="mb-4">
                 <b-input-group-prepend><b-input-group-text><i class="icon-lock"></i></b-input-group-text></b-input-group-prepend>
                 <input type="password" v-model="password" class="form-control" placeholder="Password">
               </b-input-group>
               <b-row>
                 <b-col cols="6">
                   <b-button variant="primary" class="px-4" type="button" @click="login">Login</b-button>
                 </b-col>
                 <b-col cols="6" class="text-right">
                   <b-button variant="link" class="px-0" :to="{ name: 'passwordReset' }">Forgot password?</b-button>
                 </b-col>
               </b-row>
             </b-card-body>
           </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    login () {
      this.$Progress.start()
      let router = this.$router
      this.$store.dispatch('login', {
        username: this.username,
        password: this.password
      }).then(() => {
        this.$Progress.finish()
        router.push('/')
      }, e => {
        this.$Progress.finish()
        this.showError({ title: 'Uh oh!', message: e })
      })
    }
  }
}
</script>